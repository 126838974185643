<template>
  <div>
    <v-dialog
      v-model="stage1"
      persistent
      transition="dialog-bottom-transition"
      max-width="1000"
      v-if="serverDataLoaded"
    >
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Create Invoice</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="toggleModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!--        <v-btn rounded color="primary" @click="dialog = false">-->
          <!--          Create-->
          <!--        </v-btn>-->
        </div>
        <v-card-text class="pt-6">
          <!--        entities form-->

          <form id="createForm" class="pa-3 pb-0">
            <v-row>
              <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                <v-text-field
                  v-model="formData.invoice_number"
                  label="Invoice number"
                  clearable
                  outlined
                  :error-messages="invoice_numberErrors"
                  @input="$v.formData.invoice_number.$touch()"
                  @blur="$v.formData.invoice_number.$touch()"
                ></v-text-field>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                <v-text-field
                  v-model="formData.reference"
                  label="Reference"
                  clearable
                  outlined
                ></v-text-field>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.payable_date"
                      label="Payable date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="payable_dateErrors"
                      @input="$v.formData.payable_date.$touch()"
                      @blur="$v.formData.payable_date.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.payable_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                <v-text-field
                  v-model="formData.description"
                  label="Description"
                  clearable
                  outlined
                ></v-text-field>
              </div>
            </v-row>
            <div>
              <v-card>
                <v-card-title>Services</v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="serverData.invoice.services"
                  >
                  </v-data-table>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>General</v-card-title>
                <v-card-text>
                  <div>
                    <span>Id: </span>
                    <span>{{ serverData.invoice.id }}</span>
                  </div>
                  <div>
                    <span>Currency: </span>
                    <span>{{ serverData.invoice.currency }}</span>
                  </div>
                  <div>
                    <span>Discount: </span>
                    <span>{{ serverData.invoice.discount }}</span>
                  </div>
                  <div>
                    <span>Date from: </span>
                    <span>{{ serverData.invoice.date_from }}</span>
                  </div>
                  <div>
                    <span>Date to: </span>
                    <span>{{ serverData.invoice.date_to }}</span>
                  </div>
                  <div>
                    <span>Confirmed at: </span>
                    <span>{{ serverData.invoice.confirmed_at }}</span>
                  </div>
                  <div>
                    <span>Total subtotal: </span>
                    <span>{{ serverData.invoice.total_subtotal }}</span>
                  </div>
                  <div>
                    <span>Total tax: </span>
                    <span>{{ serverData.invoice.total_tax }}</span>
                  </div>
                  <div>
                    <span>Total: </span>
                    <span>{{ serverData.invoice.total }}</span>
                  </div>
                  <div>
                    <span>Total In Sentence: </span>
                    <span>{{ serverData.invoice.totalInSentence }}</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>Client</v-card-title>
                <v-card-text>
                  <div>
                    <span>Name: </span>
                    <span>{{ serverData.invoice.client.name }}</span>
                  </div>
                  <div>
                    <span>System code: </span>
                    <span>{{ serverData.invoice.client.system_code }}</span>
                  </div>
                  <div>
                    <span>TRN: </span>
                    <span>{{ serverData.invoice.client.trn }}</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>Organization</v-card-title>
                <v-card-text>
                  <div>
                    <span>Name: </span>
                    <span>{{ serverData.invoice.organization.name }}</span>
                  </div>
                  <div>
                    <span>TRN: </span>
                    <span>{{ serverData.invoice.organization.trn }}</span>
                  </div>
                  <div>
                    <span>Country: </span>
                    <span>{{
                      serverData.invoice.organization.address.country
                    }}</span>
                  </div>
                  <div>
                    <span>City: </span>
                    <span>{{
                      serverData.invoice.organization.address.city
                    }}</span>
                  </div>
                  <div>
                    <span>Address: </span>
                    <span>{{
                      serverData.invoice.organization.address.address
                    }}</span>
                  </div>
                  <hr />
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>Bank detail</v-card-title>
                <v-card-text>
                  <div>
                    <span>Account name: </span>
                    <span>{{
                      serverData.invoice.organization.bank_detail.account_name
                    }}</span>
                  </div>
                  <div>
                    <span>Account number: </span>
                    <span>{{
                      serverData.invoice.organization.bank_detail.account_number
                    }}</span>
                  </div>
                  <div>
                    <span>Iban: </span>
                    <span>{{
                      serverData.invoice.organization.bank_detail.iban
                    }}</span>
                  </div>
                  <div>
                    <span>Bank: </span>
                    <span>{{
                      serverData.invoice.organization.bank_detail.bank
                    }}</span>
                  </div>
                  <div>
                    <span>Currency: </span>
                    <span>{{
                      serverData.invoice.organization.bank_detail.currency
                    }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <v-main class="text-center mt-4">
              <v-btn class="mr-4" color="primary" @click="submitCreateForm">
                submit
              </v-btn>
              <v-btn @click="resetCreateForm"> clear </v-btn>
            </v-main>
          </form>
        </v-card-text>
      </div>
    </v-dialog>
  </div>
</template>

<script>
// import { UPDATE_ENTITYCOLUMNS_DATA } from "@/core/services/store/entities.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      payable_date: { required },
      invoice_number: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    stage1: false,
    menu2: false,
    headers: [
      { text: "id", value: "service_id" },
      { text: "service_name", value: "service_name" },
      { text: "subtotal", value: "subtotal" },
      { text: "tax_total", value: "tax_total" },
      { text: "discount", value: "discount" },
      { text: "total", value: "total" },
    ],
    formData: {
      id: null,
      invoice_number: null,
      reference: null,
      payable_date: null,
      description: null,
    },
    serverData: null,
  }),
  mounted() {
    this.resetCreateForm();
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },

    toggleModal() {
      // if (!this.stage1)
      this.stage1 = !this.stage1;
      // console.log(this.clients);
    },
    validateData() {
      return true;
    },
    convertToFormData() {
      let data = this.formData;
      data.id = this.serverData.invoice.id;

      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.validateData()) {
          this.pageLoader(true);
          // let data = JSON.stringify(this.entCreate);
          let data = this.convertToFormData();
          // console.log("data to send is ", data);
          ApiService.post("/cod/invoices/store", data)
            .then((response) => {
              Swal.fire({
                title: "Created",
                text: `Invoice has been successfully added!`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });

              let routeData = this.$router.resolve({
                name: "invoiceTemp",
                query: { id: response.data.invoice.id },
              });
              window.open(routeData.href, "_blank");
              // setTimeout(() => {}, 0);

              this.toggleModal();
              this.refresher();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        } else {
          return null;
        }
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = { ...this.serverData };
      // console.log("hellou");
    },
    loadServerData(data) {
      this.serverData = data;
    },
  },
  computed: {
    serverDataLoaded: function () {
      return !!this.serverData;
    },
    payable_dateErrors: function () {
      return this.handleFormValidation("payable_date", this);
    },
    invoice_numberErrors: function () {
      return this.handleFormValidation("invoice_number", this);
    },
  },
};
</script>
